import React from 'react';

import './Textarea.css';

export interface TextareaProps {
  label: string
  value: string
  required: boolean
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
}
export function Textarea({label, value, onChange, required}:TextareaProps) {
  return (
    <>
      <label>{`${label}:`}</label>
      <textarea rows={6} value={value} onChange={onChange} required={required} />
    </>
  )
}