import axios from 'axios';
import { routes } from '../routes/routes';

export interface InstaData {
  id:string;
  media_url:string;
  caption:string;
  permalink:string;
}

export async function sendContactMsg(from:string, firstName:string, lastName:string, msg:string):Promise<void> {
  try {
    await axios.post('/api.php/contact', {
      from,
      firstName,
      lastName,
      msg
    });
  } catch(err) {
    console.log('Failed to send contact info!', err);
    throw err;
  }
}

export async function getInsta():Promise<InstaData[]> {
  try {
    const data = await axios.get<InstaData[]>('/api.php/insta');
    // const data = await axios.get<InstaData[]>('http://localhost:9000/api.php/insta');
    return data.data;
  } catch(err) {
    console.log('Failed to fetch insta data!', err);
    throw err;
  }
}

export interface PageTextLink {
  namn: string;
  text: string;
  till: keyof typeof routes
}
export interface PageText {
  type: "rubrik" | "rubrik-mellan" | "rubrik-liten" | "stycke" | "lista";
  text?: string;
  lank?: PageTextLink;
  items?: string[];
  className?: string;
}
export async function getPageText(route: keyof typeof routes):Promise<PageText[]> {
  try {
    const texts = await axios.get<PageText[]>(`/${route}.json`);
    return texts.data;
  } catch(err) {
    console.log(`Failed to fetch texts for ${route}.`, err);
    throw err;
  }
}