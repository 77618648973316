import { useState, useEffect } from 'react';

export enum Layout {
  S = 'small',
  M = 'medium',
  L = 'large',
};

export interface Size {
  width: number | undefined
  height: number | undefined
  layout: Layout | undefined
}

function getLayout(width:number): Layout {
  if (width < 613) return Layout.S;
  if (width < 992) return Layout.M;
  return Layout.L;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    layout: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        layout: getLayout(window.innerWidth),
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}