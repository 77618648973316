import React, { useEffect } from 'react';

export type Tag = {
  name:string
  content:string
};

export type HelmetProps = {
  meta?: Tag[],
  metaPrio?: Tag[]
  title?:string
}

export function Helmet({meta, metaPrio, title}:HelmetProps) {
  const headElement = document.head;

  useEffect(() => {    
    meta?.forEach(m => {
      for (let i = 0; i < headElement.children.length; i++) {
        const child = headElement.children[i];
        if (child.tagName === 'META' && child.getAttribute('property') === m.name) {
          headElement.removeChild(child);        
        }        
      }
      const newElement = document.createElement('meta');
      newElement.setAttribute('property', m.name);
      newElement.setAttribute('content', m.content);
      headElement.appendChild(newElement);
    });

    metaPrio?.forEach(m => {
      for (let i = 0; i < headElement.children.length; i++) {
        const child = headElement.children[i];
        if (child.tagName === 'META' && child.getAttribute('property') === m.name) {
          headElement.removeChild(child);        
        }        
      }
      const newElement = document.createElement('meta');
      newElement.setAttribute('property', m.name);
      newElement.setAttribute('content', m.content);
      headElement.prepend(newElement);
    });

    if (title) {
      document.title = title;
    }
  }, []);

  return null;
}