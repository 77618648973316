import React from 'react';
import { Helmet } from '../../components/helmet/Helmet';
import { Page } from '../../components/page/Page';

import about from './about.jpeg';
import { usePageText } from '../../hooks/usePageText';
import { DisplayPageText } from '../../components/DisplayPageText/DisplayPageText';
import { ResponsiveImageContainer } from '../../components/image/responsiveImageContainer';
import './About.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import clsx from 'clsx';

const OG_TITLE = 'Om mig';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Vi erbjuder personlig träning, pt-grupp och yoga';

const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function About() {
  const {data} = usePageText('ABOUT');
  const { layout } = useWindowSize();
  return (
    <Page className="about">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      <ResponsiveImageContainer image={about} className={clsx('about-image', layout)} />
      {data.map((t, idx) => <DisplayPageText key={idx} pageText={t} />)}
    </Page>
  )
}
