import React from 'react';
import clsx from "clsx";
import { useWindowSize } from '../../hooks/useWindowSize';

import './responsiveImageContainer.css';

export interface ResponsiveImageContainerProps {
  image: string,
  className?: string;
};
export function ResponsiveImageContainer({image, className}:ResponsiveImageContainerProps) {
  const { layout } = useWindowSize();
  return (
    <div className={clsx('image-container', className)}>
      <img src={image} alt="" className={clsx('image', layout)} />
    </div>
  );
}