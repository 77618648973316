import { useState, useEffect, useCallback } from 'react';
import { getPageText, PageText } from '../service/api';
import { routes } from '../routes/routes';

export interface PageTextI {
  loading:boolean
  error:Error|null
  data: PageText[]
}

export function usePageText(route: keyof typeof routes): PageTextI {
  const [pageText, setPageText] = useState<PageTextI>({
    loading: false,
    error:null,
    data:[]
  });

  const fetch = useCallback(async () => {
    setPageText({...pageText, loading: true});
    try {
      const data = await getPageText(route);
      setPageText({loading: false, error:null, data});
    } catch(err) {
      setPageText({loading: false, error: err as Error, data:[]});
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []); // Empty array ensures that effect is only run on mount
  return pageText;
}