import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import { Navbar } from './components/navbar/Navbar';
import { Pt } from './routes/pt/Pt';
import { Contact } from './routes/contact/Contact';
import { Home } from './routes/home/Home';
import { routes } from './routes/routes';
import { Yoga } from './routes/yoga/Yoga';
import { About } from './routes/about/About';
import { Company } from './routes/company/Company';
import { Lecture } from './routes/lecture/Lecture';
import { Physio } from './routes/physio/physio';

function App() {
  return (
    <Router>
    <div className="App">
      <Navbar />
      <Switch>
        <Route path={routes.PT}>
          <Pt />
        </Route>
        <Route path={routes.CONTACT}>
          <Contact />
        </Route>
        <Route path={routes.PHYSIO}>
          <Physio />
        </Route>
        <Route path={routes.YOGA}>
          <Yoga />
        </Route>
        <Route path={routes.COMPANY}>
          <Company />
        </Route>
        <Route path={routes.ABOUT}>
          <About />
        </Route>
        <Route path={routes.LECTURE}>
          <Lecture />
        </Route>
        <Route path={routes.START}>
          <Home />
        </Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
