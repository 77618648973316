import React, {FunctionComponent} from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../hooks/useWindowSize';

import './Page.css';

export interface PageProps {
  className?:string
}
export const Page:FunctionComponent<PageProps> = ({className, children}) => {
  const { layout } = useWindowSize(); 
  return (
    <main className={clsx('page', className,  layout)}>
      {children}      
    </main>
  )
}