import { Link } from "react-router-dom";
import { PageText } from "../../service/api";
import { routes } from "../../routes/routes";

import './DisplayPageText.css';
import clsx from "clsx";

export interface PageTextProps {
  pageText: PageText;
}
export function DisplayPageText({pageText}: PageTextProps) {
  const { type, text, lank, items, className } = pageText;
  if (type === 'rubrik') {
    return <h1>{text}</h1>;
  } else if (type === 'rubrik-mellan') {
    return <h2>{text}</h2>;
  } else if (type === 'rubrik-liten') {
      return <h3>{text}</h3>;
  } else if (type === 'lista' && items) {
    return (
      <ul className={clsx(className && className)}>
        {items.map((item, idx) => <li key={idx}>{item}</li>)}
      </ul>
    );
  } else if (lank && text) {
    const { namn, till } = lank;
    const textBeforeLink = text.substring(0, text.indexOf(`<${namn}>`));
    const textAfterLink = text.substring(text.indexOf(`<${namn}>`) + namn.length + 2);
    return (
      <p style={{whiteSpace: 'pre-wrap'}}>
        {textBeforeLink}
        <Link className="link" to={routes[till]}>{lank.text}</Link>
        {textAfterLink}
      </p>
    );
  }

  return <p style={{whiteSpace: 'pre-wrap'}} className={clsx(className && className)}>{text}</p>;
}