import React from 'react';
import { Page } from '../../components/page/Page';
import { Helmet } from '../../components/helmet/Helmet';

import yoga from './yoga.jpg';
import physio from './pt.jpg';
import pt from './online.jpg';
import pageImage from './pageImage.jpg';
import './Home.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import clsx from 'clsx';
import { InstaList } from '../../components/insta/InstaList';
import { InstaHeader } from '../../components/insta/InstaHeader';
import { ResponsiveImageContainer } from '../../components/image/responsiveImageContainer';
import { usePageText } from '../../hooks/usePageText';
import { DisplayPageText } from '../../components/DisplayPageText/DisplayPageText';
import { Link } from 'react-router-dom';
import { routes } from '../routes';

const OG_TITLE = 'Välkommen!';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Introduktion av Mette Zmuda på Movety';
const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function Home() {
  const { layout } = useWindowSize();
  const {data: pageText} = usePageText('START')

  return (
    <Page className="home">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      <ResponsiveImageContainer image={pageImage} />
      <div className={clsx('page-container', layout)}>
        {pageText.map((t, idx) => <DisplayPageText key={idx} pageText={t} />)}
        <div className={clsx('image-container', layout)}>
          <Link to={routes.PT} className="imgLink">
            <img src={pt} alt="" />
            <span className="img-text">PT</span>
          </Link>
          <Link to={routes.PHYSIO} className="imgLink">
            <img src={physio} alt="" />
            <span className="img-text">Fysioterapi</span>
          </Link>
          <Link to={routes.YOGA} className="imgLink">
            <img src={yoga} alt="" />
            <span className="img-text">Yoga</span>
          </Link>
        </div>
      </div>
      <InstaHeader />
    </Page>
  )
}
