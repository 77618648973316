import React from 'react';
import { Helmet } from '../../components/helmet/Helmet';
import { Page } from '../../components/page/Page';

import './Yoga.css';
import { usePageText } from '../../hooks/usePageText';
import { DisplayPageText } from '../../components/DisplayPageText/DisplayPageText';

const OG_TITLE = 'Yoga';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Vi erbjuder personlig träning, pt-grupp och yoga';

const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function Yoga() {
  const {data} = usePageText('YOGA')
  return (
    <Page className="yoga">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      {data.map((t, idx) => <DisplayPageText key={idx} pageText={t} />)}
    </Page>
  )
}
