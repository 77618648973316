import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Input } from '../../components/input/Input';
import { Submit } from '../../components/input/Submit';
import { Textarea } from '../../components/input/Textarea';
import { Page } from '../../components/page/Page';
import { Helmet } from '../../components/helmet/Helmet';
import { sendContactMsg } from '../../service/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './Contact.css';

enum MSG_SENT_STATUS {
  SENDING,
  SUCCESS,
  FAILED,
  DONE,
}
const OG_TITLE = 'Kontaktformulär';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Skicka gärna ett meddelande vi vårt kontaktformulär om du undrar över något!';

const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function Contact() {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [msgSentStatus, setMsgSentStatus] = useState(MSG_SENT_STATUS.DONE);

  const handleSubmit = async (event:FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMsgSentStatus(MSG_SENT_STATUS.SENDING);
    try {
      await sendContactMsg(email, firstName, lastName, msg);
      toast.success("Meddelande skickat!");
      setMsgSentStatus(MSG_SENT_STATUS.SUCCESS);      
    } catch(err) {
      setMsgSentStatus(MSG_SENT_STATUS.FAILED);
      toast.error("Misslyckades att skicka meddelande! Försök igen om en liten stund.");
    }
  };

  return (
    <Page className="contact">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      <h1>Kontaktformulär</h1>      
      <form onSubmit={handleSubmit}>
        <Input type="email" label="Epost" value={email} onChange={(event:FormEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)} required />          
        <Input type="text" label="Förnamn" value={firstName} onChange={(event:FormEvent<HTMLInputElement>) => setFirstName(event.currentTarget.value)} required />
        <Input type="text" label="Efternamn" value={lastName} onChange={(event:FormEvent<HTMLInputElement>) => setLastName(event.currentTarget.value)} required />
        <Textarea label="Meddelande" value={msg} onChange={(event:ChangeEvent<HTMLTextAreaElement>) => setMsg(event.currentTarget.value)} required />
        <Submit className="submit" value="Skicka" disabled={msgSentStatus === MSG_SENT_STATUS.SENDING} />
      </form>
      <ToastContainer />
    </Page>    
  )
}