import React from 'react';
import clsx from 'clsx';

import './Input.css';

export interface SubmitProps {
  value:string
  className?:string
  disabled:boolean
}

export function Submit({value, className, disabled}:SubmitProps) {
  return <input type="submit" className={clsx(className)} value={value} disabled={disabled} />
}