import React, { useCallback, useState } from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';
import { Layout, useWindowSize } from '../../hooks/useWindowSize';
import { routes } from '../../routes/routes';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { ReactComponent as Logo } from './logo.svg';
import './Navbar.css';
import clsx from 'clsx';

interface LogoLinkProps {
  onClick?: () => void;
}
const LogoLink = ({onClick}: LogoLinkProps) => <Link to={routes.START} onClick={onClick}><Logo className="logo" title='Movety logo' /></Link>;

function getSelectedClassName(locationPath:string, pathname:string):string {
  return locationPath === pathname ? 'selected' : '';
}

export function Navbar() {
  const { layout } = useWindowSize();
  return (
    <>
    {(layout === Layout.M || layout === Layout.L) && (
      <LargeMediumNavbar />
    )}
    {layout === Layout.S && (
      <SmallNavbar />
    )}
    </>
  )
}

function LargeMediumNavbar() {
  const location = useLocation();
  const [isWorkoutMenuOpen, setIsWorkoutMenuOpen] = useState<boolean>(false);

  const handleWorkoutClick = useCallback(() => {
    setIsWorkoutMenuOpen(!isWorkoutMenuOpen);
  }, [isWorkoutMenuOpen])

  const closeOpenMenu = () => {
    setIsWorkoutMenuOpen(false);
  }

  return (
    <nav className="navigation">
      <LogoLink onClick={closeOpenMenu} />
      <div className="section">
        <button
          id="workout-button"
          className="menuButton"
          onClick={handleWorkoutClick}
          aria-controls={isWorkoutMenuOpen ? 'workout-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isWorkoutMenuOpen ? 'true' : undefined}
        >
          Träning
        </button>
        {isWorkoutMenuOpen && (
          <ul id="workout-menu" className="menu" aria-labelledby="workout-button">
            <li>
              <Link to={routes.PT} onClick={handleWorkoutClick} className={getSelectedClassName(location.pathname, routes.PT.toString())}>Personlig träning</Link>
            </li>
            <li>
              <Link to={routes.YOGA} onClick={handleWorkoutClick} className={getSelectedClassName(location.pathname, routes.YOGA.toString())}>Yoga</Link>
            </li>
            <li>
              <Link to={routes.COMPANY} onClick={handleWorkoutClick} className={getSelectedClassName(location.pathname, routes.COMPANY.toString())}>Företag</Link>
            </li>
          </ul>
        )}
        <Link to={routes.PHYSIO} onClick={closeOpenMenu} className={getSelectedClassName(location.pathname, routes.PHYSIO.toString())}>Fysioterapi</Link>
        <Link to={routes.LECTURE} onClick={closeOpenMenu} className={getSelectedClassName(location.pathname, routes.LECTURE.toString())}>Föreläsningar</Link>
        <Link to={routes.ABOUT} onClick={closeOpenMenu} className={getSelectedClassName(location.pathname, routes.ABOUT.toString())}>Om</Link>
        <Link to={routes.CONTACT} onClick={closeOpenMenu} className={getSelectedClassName(location.pathname, routes.CONTACT.toString())}>Kontakt</Link>
      </div>
    </nav>
  )
}

function SmallNavbar() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  return (
    <nav className="small">
      <div className="navigation top">
        <span className="start"></span>
        <LogoLink />
        <div className="menu-button">
          <button onClick={() => setOpen(!open)}>
            {open && (<MenuOpenIcon />)}
            {!open && (<MenuIcon />)}
          </button>
        </div>
      </div>
      {open && (<div className="menu">
        <p className="header">Träning</p>
        <Link to={routes.PT} onClick={() => setOpen(false)} className={clsx(getSelectedClassName(location.pathname, routes.PT.toString()), 'submenu')}>Personlig träning</Link>
        <Link to={routes.YOGA} onClick={() => setOpen(false)} className={clsx(getSelectedClassName(location.pathname, routes.YOGA.toString()), 'submenu')}>Yoga</Link>
        <Link to={routes.COMPANY} onClick={() => setOpen(false)} className={clsx(getSelectedClassName(location.pathname, routes.COMPANY.toString()), 'submenu')}>Företag</Link>
        <Link to={routes.PHYSIO} onClick={() => setOpen(false)} className={getSelectedClassName(location.pathname, routes.PHYSIO.toString())}>Fysioterapi</Link>
        <Link to={routes.LECTURE} onClick={() => setOpen(false)} className={getSelectedClassName(location.pathname, routes.LECTURE.toString())}>Föreläsningar</Link>
        <Link to={routes.ABOUT} onClick={() => setOpen(false)} className={getSelectedClassName(location.pathname, routes.ABOUT.toString())}>Om</Link>
        <Link to={routes.CONTACT} onClick={() => setOpen(false)} className={getSelectedClassName(location.pathname, routes.CONTACT.toString())}>Kontakt</Link>
      </div>)}
    </nav>
  )
}