import React from 'react';

import './Input.css';

export interface InputProps {
  label:string
  value:string
  type:string
  required:boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}
export function Input({label, value, type, onChange, required}:InputProps) {  
  return (
    <>
      <label htmlFor={label}>{label}</label>      
      <input id={label} type={type} value={value} onChange={onChange} required={required} />  
    </>
  )
}
/*{type === 'email' && (
  <input id={label} type={type} value={value} onChange={onChange} required={required} pattern=""/>  
  )}
  {type !== 'email' && (
    <input id={label} type={type} value={value} onChange={onChange} required={required} />  
  )}      */
//(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])