import React from 'react';
import instagram from './instagram.svg';
import './InstaHeader.css';

export function InstaHeader() {
  return (
    <a href="https://www.instagram.com/mettezmuda" className="insta-header">
      <img src={instagram} alt="Instagram" />
      <span>Mette Zmuda | MOVETY</span>
    </a>
  );
}